var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex_layout"},_vm._l((_vm.albums),function(item,index){return _c('div',{key:index,staticClass:"albums"},[(item.artists)?_c('div',{staticClass:"play_cover",staticStyle:{"position":"relative","justify-items":"inherit","align-items":"inherit"}},[_c('button',{staticClass:"play_button",on:{"click":function($event){return _vm.playAlbum(item.id)}}},[_c('svg',{staticClass:"glyph",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 27 27"}},[_c('path',{attrs:{"d":"M11.3545232,18.4180929 L18.4676039,14.242665 C19.0452323,13.9290954 19.0122249,13.1204156 18.4676039,12.806846 L11.3545232,8.63141809 C10.7603912,8.26833741 9.98471883,8.54889976 9.98471883,9.19254279 L9.98471883,17.8404645 C9.98471883,18.5006112 10.7108802,18.7976773 11.3545232,18.4180929 Z"}})])]),_c('button',{staticClass:"add_button play_button",on:{"click":function($event){return _vm.addAlbum(item.id)}}},[_c('svg',{staticClass:"glyph",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"27","height":"27","viewBox":"0 0 27 27"}},[_c('path',{attrs:{"d":"M8.89547677 13.5330073c0-1.1057457-.97371638-2.0959657-2.09596577-2.0959657-1.13875305 0-2.0794621.99022-2.0794621 2.0959657 0 1.1222494.94070905 2.0794621 2.0794621 2.0794621 1.12224939 0 2.09596577-.9572127 2.09596577-2.0794621zm6.68398533 0c0-1.1057457-.9572127-2.0959657-2.0794621-2.0959657-1.1222494 0-2.0794621.99022-2.0794621 2.0959657 0 1.1222494.9572127 2.0794621 2.0794621 2.0794621 1.1222494 0 2.0794621-.9572127 2.0794621-2.0794621zm6.700489 0c0-1.1057457-.940709-2.0959657-2.0794621-2.0959657-1.1222494 0-2.0959658.99022-2.0959658 2.0959657 0 1.1222494.9737164 2.0794621 2.0959658 2.0794621 1.1387531 0 2.0794621-.9572127 2.0794621-2.0794621z"}})])]),_c('router-link',{staticClass:"play_cover",attrs:{"to":{
                    name: 'Album',
                    params: { id: item.id, name: item.name },
                }}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.picUrl + '?param=150y150'),expression:"item.picUrl + '?param=150y150'"}],key:item.picUrl + '?param=150y150',staticClass:"album_cover",attrs:{"alt":item.name,"title":item.name}})])],1):_vm._e(),(item.program)?_c('router-link',{staticClass:"play_cover",attrs:{"to":""}},[_c('button',{staticClass:"play_button",on:{"click":function($event){return _vm.playSong(item.program.mainTrackId)}}},[_c('svg',{staticClass:"glyph",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 27 27"}},[_c('path',{attrs:{"d":"M11.3545232,18.4180929 L18.4676039,14.242665 C19.0452323,13.9290954 19.0122249,13.1204156 18.4676039,12.806846 L11.3545232,8.63141809 C10.7603912,8.26833741 9.98471883,8.54889976 9.98471883,9.19254279 L9.98471883,17.8404645 C9.98471883,18.5006112 10.7108802,18.7976773 11.3545232,18.4180929 Z"}})])]),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.program.coverUrl + '?param=150y150'),expression:"item.program.coverUrl + '?param=150y150'"}],key:item.program.coverUrl + '?param=150y150',staticClass:"album_cover",attrs:{"alt":item.name,"title":item.name}})]):_vm._e(),(item.artists)?_c('div',{staticClass:"info"},[_c('router-link',{attrs:{"to":{
                    name: 'Album',
                    params: { id: item.id, name: item.name },
                }}},[_vm._v(_vm._s(item.name))]),_c('router-link',{attrs:{"to":{
                    name: 'Artist',
                    params: { id: item.artist.id, name: item.artist.name },
                }}},[_vm._v(" "+_vm._s(item.artists[0].name))])],1):_vm._e(),(item.program)?_c('div',{staticClass:"info"},[_c('router-link',{attrs:{"to":""}},[_vm._v(_vm._s(item.program.description))]),_c('router-link',{attrs:{"to":""}},[_vm._v(" "+_vm._s(item.program.name))])],1):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }